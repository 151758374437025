#serverside_table {
  td:last-child,
  th:last-child {
    min-width: 65px;
  }
}

.btn-mini {
  border-radius: 30px!important;
  padding: 0px 7px!important;
}

.box-plant {
  h1, h2, h3, h4, h5, h6 {
    color: white;
  }
  .huge {
    font-size: 2.4rem;
    color: white;
    font-weight: 800;
  }
  .details {
  font-size: 1.9rem;
    color: black;
    font-weight: 800;
  }
}

a.site {
  font-size: 2.1rem;
  color: $brand-warning !important;
  text-transform: uppercase;
  font-weight: 800;
}

.nav-pills {
  li {
    a {
      color: #555555;
    }
  }
}